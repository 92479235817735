import wzEventhandler from "../wz.eventhandler";

class cgDomSizer {
    constructor() {
        this.domElementSizes = {};
        this.documentRoot = document.querySelector(':root');
    }

    getAllDomSizeElements(){
        this.domElementSizes = {};
        var self = this;
        var domElements = document.querySelectorAll('[data-sizer]');
        domElements.forEach(function(element) {
            // Get the class name of the current element
            var domElementName = element.getAttribute('data-sizer');
            if(typeof self.domElementSizes[domElementName] == "undefined"){
                self.domElementSizes[domElementName] = element.offsetHeight;
            }
        });

        domElements = document.querySelectorAll('[data-sizer-dynamic]');
        domElements.forEach(function(element) {
            // Get the class name of the current element
            var domElementName = element.getAttribute('data-sizer-dynamic');
            if(typeof self.domElementSizes[domElementName] == "undefined" || (self.domElementSizes[domElementName] && self.domElementSizes[domElementName] < element.offsetHeight) ){
                self.domElementSizes[domElementName] = element.offsetHeight;
            }
        });
    }

    getElementsPosition(){
        this.domElementPosition = {};
        var self = this;
        var domElements = document.querySelectorAll('[data-positioner]');
        domElements.forEach(function(element) {
            // Get the class name of the current element
            var domElementName = element.getAttribute('data-positioner');
            if(typeof self.domElementPosition[domElementName] == "undefined"){
                self.domElementPosition[domElementName] = element.getBoundingClientRect();
            }
            
        });

    }

    setAllScssVarPositions(){
        var r = document.querySelector(':root');
        for (const [key, value] of Object.entries(this.domElementPosition)) {
            r.style.setProperty(`--dynY${key}`, value.y + "px" );
            r.style.setProperty(`--dynX${key}`, value.x + "px");
        }
    }

    setAllScssVarHeights(){
        var r = document.querySelector(':root');
        for (const [key, value] of Object.entries(this.domElementSizes)) {
            r.style.setProperty(`--dyn${key}`, value + "px");
        }
    }

    init() {
        this.getAllDomSizeElements();
        this.setAllScssVarHeights();
        this.getElementsPosition();
        this.setAllScssVarPositions();
    }
}

$(function () {
    var cgSizer = new cgDomSizer();
    window.cgSizer = cgSizer;
    cgSizer.init();

    if (typeof wzEventHandler !== 'undefined' && typeof wzEventHandler.onWindowScroll !== 'undefined') {
        wzEventHandler.onWindowScroll(cgSizer.init());
    }


});
